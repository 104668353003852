export default {

    methods: {
  
      formatCurrency(value) {
        var formatter = new Intl.NumberFormat("da-DK", {
          minimumFractionDigits: 2
        });
        return formatter.format(value);
      },
  
      formatDate(value) {
  
        var d = new Date(value).toLocaleString('da-DK', {
          dateStyle: 'short',
        })
        return d;
      },
  
      arraymove(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      },

        //------------------------------------------------------------------------------
        //	TIME SINCE e.g 5 minutes ago
        //------------------------------------------------------------------------------

        timeSince(datetime) {

            const rtf = new Intl.RelativeTimeFormat('da', {
                numeric: "always", // other values: "auto"
                style: "narrow", // other values: "short" or "narrow" });
            })

            var suppliedDate = new Date(datetime)
            suppliedDate.setHours(suppliedDate.getHours() - 2); // Hack, cause of different timezone

            var currentDate = new Date(Date.now())
            var seconds = Math.floor(( currentDate - suppliedDate ) / 1000)

            var interval = seconds / 31536000;
            if (interval >= 1) {
            return rtf.format(Math.floor(interval) * -1, 'year');
            }
            interval = seconds / 2592000;
            if (interval >= 1) {
            return rtf.format(Math.floor(interval) * -1, 'month');
            }
            interval = seconds / 86400;
            if (interval >= 1) {
            return rtf.format(Math.floor(interval) * -1, 'day');
            }
            interval = seconds / 3600;
            if (interval >= 1) {
            return rtf.format(Math.floor(interval) * -1, 'hour');
            }
            interval = seconds / 60;
            if (interval >= 1) {
            return rtf.format(Math.floor(interval) * -1, 'minute');
            }
            return rtf.format(Math.floor(seconds) * -1, 'second');
      },

      GTM_push_pageview_layer(input, first_page_load) {

        var page_title 	= ( typeof input == "object" && input.page_title ) ? input.page_title : document.title;
        var page_URL 	= ( typeof input == "object" && input.page_URL ) ? input.page_URL : window.location.href;
        var page_path 	= ( typeof input == "object" && input.page_path ) ? input.page_path :window.location.pathname;
        var page_hash 	= ( typeof input == "object" && input.page_hash ) ? input.page_hash : window.location.hash;
      
        var data = {
          'event': 'Pageview',
          'page_title': (page_title) ? page_title : '',
          'page_URL': (page_URL) ? page_URL: '',
          'page_path': (page_path) ? page_path + document.location.search : '',
          'page_hash': (page_hash) ? page_hash : '',
        }

      
        // Tjek om OG location findes i sessions storage
        if( sessionStorage.getItem('OGLOCATION') !== null ) { // Hvis den findes => Send værdi med
      
          data.originalLocation = sessionStorage.getItem('OGLOCATION')
      
        } else { // Hvis den ikke findes => opret værdi og gem i sessionsstorage og send med.
      
          data.originalLocation = document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search
          sessionStorage.setItem('OGLOCATION', data.originalLocation)
      
        }
      
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
      
      }

  
    }
  
  }