<template></template>
<script>
export default {

  methods: {
      handler: function(e) {
        this.$store.dispatch('log_data', e)
      }
  },
  created() {
    // Start custom tracker - for leadscoring.
    document.addEventListener('click', this.handler)
  },
  destroyed() {
      document.removeEventListener('click', this.handler)
  }
}

</script>
