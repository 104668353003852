<template>
<div class="contact" :class="{ 'is_open' : is_open }">
    <div class="contact__backdrop" @click="close"></div>
    <div class="contact__content">

        <div class="contact__close"  @click="close">
            <Svgicon icon="icons/cross" />
        </div>

        
        
        <div class="contact__text">
            <transition name="fade" mode="out-in">
            <div v-if="!done" key="text">
                <h2>Lad os kontakte dig</h2>
                <p><b>Hej <span v-if="user?.user_companyname">{{user.user_companyname}}</span></b></p>
                <p>Vi tager altid gerne <b>en uforpligtende snak</b> om mulighederne for en skrædderesyet aftale til netop din virksomhed.<br>
Indtast blot dit telefonnummer herunder, så ringer vi dig op i dag eller senest den næstkomne hverdag.</p>

                <div class="field field--title">
                    <label for="phone">Telefon</label>
                    <input type="tel" id="phone" name="phone" placeholder="Dit direkte telefonnummer" v-model="phone">
                </div>
                <br>
                
                <button class="btn btn--green btn--center" @click="send_contact_mail">Kontakt mig med et uforpligtende tilbud</button>
            </div>
            <div v-else key="done">
                <h2>Vi har modtaget dine indsendte oplysninger.</h2>
                <p>Du vil nu blive ringet op af én af vores konsulenter, enten i dag eller den næstkommende hverdag. Så kan I sammen tage en uddybende snak om din virksomhed og mulighederne for håndværkere hos 3byggetilbud.dk. </p>

                <p>Vil du vide mere om, hvad andre håndværkere mener om deres samarbejde med 3byggetilbud.dk? Så kan du se udtalelser fra nogle af vores samarbejdspartnere i vores sektion med <a href="https://www.3byggetilbud.dk/erfaringer/" target="_blank">håndværkernes erfaringer med 3byggetilbud.dk.</a></p>
            </div>
            </transition>
        </div>
        

    </div>
</div>
</template>

<script>

import { useToast } from "vue-toastification";
export default {

  data() {
    return {
        phone: '',
        done: false,
    }
  },
  computed: {
      is_open() {
          let is_open = this.$store.getters.contact_open
          return is_open
      },
      user() {
          return this.$store.getters.current_user
      }
    
  },

  methods: {
        close() {
           this.$store.dispatch('contact_toggle', 0)
           this.$store.dispatch('spinner_toggle', 0)
        },

        send_contact_mail() {

            var that = this

            const toast = useToast();
            if( this.phone.length < 8 ) { toast.error("Er telefonnummeret 8 tal?"); return false; }

            that.$store.dispatch('spinner_toggle', 1)

            const data = {
                company_name: that.user.user_companyname,
                name: that.user.user_email,
                hash: that.user.user_hash, 
                phone: that.phone
            }

            this.$store.dispatch('send_contact_mail', data).then( (response) => {
                console.log(response)
                that.$store.dispatch('spinner_toggle', 0)

                if( response == undefined ) {
                    toast.error("Netværksfejl - prøv igen om lidt"); 
                } else {
                    window.location = "https://www.3byggetilbud.dk/tak-for-ansoegning/"
                    that.done = true;  
                }
                
                
            })

        }
  },



};
</script>

<style lang="scss">

.contact {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.3s $easeInOutSine;
    z-index: 9999;

    &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#333, 0.5);

    }

    &__content {
        position: absolute;
        z-index: 2;
        background: white;
        padding: 3rem;
        border-radius: $radius;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -30%);
        max-width: 650px;
        width: 90%;
        transition: 0.3s $easeOutCubic;
        transition-delay: 0.1s;
        @include small {
            padding: 2rem 2rem;
        }
        @include mobile {
            padding: 2rem 1rem;
        }
    }

    
    &__text {
        
        h2 {
            margin-bottom: 1rem;
        }
        p {
            margin-bottom: 1.5rem;
        }
        
    }   

    &__close {
        position: absolute;
        width: 35px;
        right: 10px;
        top: 10px;
        padding: 5px;
        border-radius: 50px;
        box-shadow: $shadow2;
        line-height: 0;
        cursor: pointer;
    }

    &.is_open {
        opacity: 1;
        visibility: visible;
        pointer-events: all;

        .contact__content {
            transform: translate(-50%, -50%);
        }
    }

     @include mobile {
        .btn {
            display: block;
            width: 100%;
            margin-top: 0.8rem;
        }
    }
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
