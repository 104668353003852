
<template>

  <div class="default_layout">

    <header class="header">

        <div class="burger" @click="mobilemenu_open = !mobilemenu_open">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div class="header__logo" @click="logo_click()">
          <Svgicon icon="logo" />
        </div>

        <div class="call">

          <span class="call__prefix">I tvivl om noget?</span>
          <a class="call__number" href="tel:+4577334018">77 33 40 18</a>
          <span class="call__suffix">Kl 9-16 i dag</span>
          <Svgicon icon="phone_desktop" class="phone_desktop"/>

          <a class="phone_mobile" href="tel:+4577334018"><Svgicon icon="phone_mobile"/></a>

        </div>

    </header>

    <aside>
      <Navbar @routeChanged="mobilemenu_open = false" />
    </aside>
    <div class="mobilebackdrop" @click="mobilemenu_open = false"></div>

    <main>
        <slot></slot>
        <div class="footer">
          <span><a href="https://www.3byggetilbud.dk" target="_blank">©3byggetilbud.dk</a></span>
          |
          <span>Kontakt os på <a href="tel:77334000">+45 77 33 40 00</a> eller <a href="mailto:pro@3byggetilbud.dk">pro@3byggetilbud.dk</a></span></div>
    </main>

    <div id="teleport_outside_main"><!-- vue teleport modals etc. to here --></div>

    <LimitedAccessModal />
    <ContactModal />
    <TrialExpiredModal />
    <Loading />

  </div>

</template>

<script>
import Navbar from "@/components/Navbar";
import LimitedAccessModal from "@/components/LimitedAccessModal";
import ContactModal from "@/components/ContactModal";
import TrialExpiredModal from "@/components/TrialExpiredModal";
import Loading from "@/components/Loading";

export default {
  components: {
    Navbar,
    LimitedAccessModal,
    ContactModal,
    TrialExpiredModal,
    Loading
  },

  data() {
      return {
          mobilemenu_open: false,
      }
  },

  methods: {

    logo_click() {
     this.$router.push('/dashboard/'+ this.$route.hash)
    }
  },

  created() {

    this.$watch('mobilemenu_open', (currentValue, oldVal) => {
      if( currentValue ) {
          document.getElementsByTagName( 'html' )[0].classList.add('mobile_menu_open')
        } else {
          document.getElementsByTagName( 'html' )[0].classList.remove('mobile_menu_open')
        }
    })

    
    const jobs = this.$store.dispatch( 'get_latestjobs' );
    

  },
};
</script>



<style lang="scss">

  $headerHeight : 80px;
  $headerHeight_mobile: 60px;
  $asideWidth : 250px;

  .header {
    width: 100%;
    height: $headerHeight;
    box-shadow: $shadow3;
    background: white;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;

    display: flex;
    justify-content: space-between;

    @include medium {
      height: $headerHeight_mobile;
      background: $color1;
    }

    &__logo {
        display: block;
        height: 100%;
        background: $color1;
        width: $asideWidth;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(white, 0.5);
        cursor: pointer;

        .svgicon {
          width: 100px;
          display: block;
          margin: 0 auto;
          color: white;
        }

        @include medium {
          width: auto;
          border-bottom: none;

          .svgicon {
            width: 85px;
          }
        }
    }

  }

  .call {
    width: 220px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__prefix {
      font-size: 0.75rem;
    }
    &__number {
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.2;
      padding-top: 2px;
    }
    &__suffix {
      font-size: 0.75rem;
    }

    .phone_desktop {
      pointer-events: none;
      position: absolute;
      width: 300px;
      right: -90px;
      bottom: -15px;
    }

    .phone_desktop {
      display: block;
    }
    .phone_mobile {
      display: none;
    }

    @include medium {

      width: auto;
      &__prefix,
      &__number,
      &__suffix  {
        display: none;
      }

      .phone_desktop {
        display: none;
      }
      .phone_mobile {
        display: block;
        width: 30px;
        margin-right: $mobile_gutter;
      }

    }

  }

  aside {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: $asideWidth;
    height: 100%;
    background: $color1;
    color: white;
    transition: 0.45s $easeInOutExpo;
    z-index: 99;

    @include medium {
      top: $headerHeight_mobile;
      visibility: hidden;
      transform: translateX(-100%);
      pointer-events: none;
    }
  }

  main {
    padding-top: $headerHeight;
    padding-left: $asideWidth;

    @include medium {
      padding-top: $headerHeight_mobile;
      padding-left: 0;
    }
  }


  .mobilebackdrop {
    position: absolute;
    top: $headerHeight_mobile;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba($color1, 0.15);
    z-index: 98;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.2s;
  }


  .burger {
  	display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30px;
    height: 100%;
    cursor: pointer;
    @include gutter('margin-left');
    &:hover,
    &:active {
      span:nth-child(3) {
        width: 100%;
      }
    }

    span {
      width: 100%;
      height: 3px;
      background: white;
      margin-bottom: 5px;
      display: block;
      transition: 0.2s $easeInOutSine;
      transform-origin: left;
      border-radius: 10px;
    }

    span:nth-child(2) {
      width: 60%;
    }

    span:nth-child(3) {
      width: 80%;
      margin-bottom: 0;
    }

    @include medium_up {
      display: none;
    }
}

html.mobile_menu_open {

    overflow: hidden;

    aside {
        visibility: visible;
        transform: translateX(0%);
        pointer-events: all;
    }

    .mobilebackdrop {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }

    .burger {
      span:nth-child(1) {
        transform: rotate(45deg) translate(-3px, 0px);
      }
      span:nth-child(2) {
        width:0;
      }
      span:nth-child(3) {
        width: 100%;
        transform: rotate(-45deg) translate(-3px, 1px);
      }
    }

}

.footer {
  position: relative;
  z-index: 1;
  margin-top: 1rem;
  font-size: 0.8rem;
  padding-bottom: 1rem;
  padding-left: $gutter;
  padding-right: $gutter;
  @include mobile {
    padding-left: $mobile_gutter;
    padding-right: $mobile_gutter;
  }

  span:first-child {
    margin-right: 15px;
  }
  span:last-child {
    margin-left: 15px;
  }
}

  

</style>