
import { createStore } from "vuex" 
import createPersistedState from "vuex-persistedstate";
//import router from '../router'
// import { useToast } from "vue-toastification";

const store = createStore({
  plugins: [createPersistedState()],

    state () {
      return {
        user: null,
        limitedAccess_open: 0,
        contact_open: 0,
        spinner: 0,
        latestjobs: null,
        trades: null,
      }
    },

    getters: {
      current_user(state) {
        return state.user
      },

      hash(state) {

        if( state?.user?.user_hash ) {
          return state.user.user_hash
        }
        return false;
      },

      ip(state) {

        if( state?.user?.ip ) {
          return state.user.ip
        }
        return false;
      },

      days_since_signup(state) {

        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const formatted_db_date = state.user._created.replace(/-/g, "/"); // Safari hack fix
        const firstDate = new Date(formatted_db_date);
        
        const secondDate = new Date();
        
        return Math.round(Math.abs((firstDate - secondDate) / oneDay));

      },

      days_left(state, getters) {

        var days_gone = getters.days_since_signup

        var diff = 14 - days_gone;
        if( diff < 1 ) return 0
        return diff;

      },

      limitedAccess_open(state) {
        return state.limitedAccess_open
      },
      contact_open(state) {
        return state.contact_open
      },
      spinner(state) {
        return state.spinner
      },

      latest_jobs(state) {
          if( state.latestjobs !== null) {
            return state.latestjobs.slice(0, 5);
          } 
          return null
        
      },

      all_jobs(state) {
        if( state.latestjobs !== null) {

          if( state.latestjobs.length > 25) {
            return state.latestjobs.slice(0, 20);
          }
          return state.latestjobs
        }
          return null
        
      },

      trades(state) {
        if( state.trades !== null ) {
          return state.trades.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        }
        return state.trades
      },

      is_expired (state, getters) {
        
        var current_user = getters.current_user;
        var ip = getters.ip;
        
        if( current_user == null ) return true; // No user <=> expired.

        if( current_user.user_no_expiration) {
            if( ip == '62.116.206.218' || ip == '89.221.169.42' || ip == '::1' ) {
              return false;
            }
        }

        if( getters.days_since_signup > 30 ) {
          return true;
        }

        return false
      },

      getJobByReference: (state) => (ref) => {
        if( state.latestjobs !== null) {
          return state.latestjobs.find(job => job.reference === ref)
        }
        return null
      }

    },

    mutations: {
      store_user (state, user) {
        state.user = user
      },

      store_latestjobs (state, jobs) {
        state.latestjobs = jobs
      },

      limitedAccess_toggle (state, payload) {
        state.limitedAccess_open = payload
      },
      contact_toggle (state, payload) {
        state.contact_open = payload
      },
      spinner_toggle (state, payload) {
        state.spinner = payload
      },
      store_trades (state, payload) {
        state.trades = payload
      },
      
    },

    actions: {

      limited_access_toggle ({ commit, state }, payload) {
        commit('limitedAccess_toggle', payload) // true | false
      },
      contact_toggle ({ commit, state }, payload) {
        commit('contact_toggle', payload) // true | false
      },
      spinner_toggle ({ commit, state }, payload) {
        commit('spinner_toggle', payload) // true | false
      },

      async create_user ({ commit, state }, payload) {

        // Include UTM parameters in the request
        const utm = JSON.parse(localStorage.getItem('utm'));
        if( utm !== undefined || utm !== null ) {
          payload.utm = utm;
        }

        const response = await this.axios.post( process.env.VUE_APP_APIROOT + '/api/?e=create_user', payload )
        if( response.data.status == 'success') {

            // If payload dont have property user_consultant, then it's a user signup.
            if( !payload.hasOwnProperty('user_consultant') ) {
              if( typeof fbq == 'function') fbq('track', 'Lead');
              if( typeof gtag == 'function') {
                gtag('event', 'demo_sign_up');
              }
            }

            commit('store_user', response.data.data)
            return response.data

        } else {
            return response.data
        }

      },

      async fetch_user_from_hash ({ commit, state, getters }, url_hash) {
        
        // Get hash from url or fallback from store.
        var hash = (url_hash) ? url_hash : getters.hash;

        if( hash == '' ) return;

        // Check expiration
        await this.axios.post( process.env.VUE_APP_APIROOT + '/api/?e=get_user_from_hash', { "hash": hash} ).then((response) => {  
          
          if( response.data.status == 'success') {
          
            commit('store_user', response.data.data)
            return response.data.data
          }
          
          return;

        })

      },


      async send_contact_mail({ commit, state, getters }, payload) {

        // Save / update phone no.
        var save = await this.axios.post(process.env.VUE_APP_APIROOT + '/api/?e=save_phone_number', payload)
   
        // Send mail
        payload.portal = 'demo.3bygggetilbud.dk'
        let resp = undefined
        await this.axios.post("https://www.3byggetilbud.dk/wp-json/abg/v1/craftmansignup_mail", payload).then( (response) => { 
          resp = {status: 'success', message: response};  
        }).catch(function(error) {
          resp = {status: 'failed', message: error};  
        })
        return resp;
      },

      get_latestjobs({ commit, state, getters }) {

        this.axios.get("https://api.3byggetilbud.dk/websites/pro/projects/new").then( (response) => {
          commit('store_latestjobs', response.data.projects)
        })
        
      },

      get_trades({ commit, state, getters }) {

        if( getters.trades === null ) {

          this.axios.get("https://api.3byggetilbud.dk/websites/pro/trades").then( (response) => {
            console.log(response)
            commit('store_trades', response.data.trades)
          })

        } else {

          return getters.trade;

        }
       
      },

      log_data({commit, state, getters}, event) {

        var data = {}

        var current_user = getters.current_user;
        if( current_user ) {
          data.fk_user_id = current_user.id
        }
        
        this.axios.post( process.env.VUE_APP_APIROOT + '/api/?e=log', data )
      }

    },


  })

export default store