<template>
<div class="limitedAccess" :class="{ 'is_open' : is_open }">
    <div class="limitedAccess__backdrop" @click="close"></div>
    <div class="limitedAccess__content">

        <div class="limitedAccess__close"  @click="close">
            <Svgicon icon="icons/cross" />
        </div>

        <div class="limitedAccess__flex">
            <div class="limitedAccess__anim">
            
            <Vue3Lottie :animationData="direktoerAnim" :autoPlay="true" :loop="true"/>
            </div>

            <div class="limitedAccess__text">
                <h2>Din konto har begrænset adgang.</h2>
                <p>Hvis du ønsker adgang til alle vores funktioner, og en fasttilknyttet konsulent som hver måned fodrer dig med den ene lækre opgave efter den anden, skal du først opgradere til en betalende plan.</p>
                <a class="btn btn--border" href="https://www.3byggetilbud.dk/tilmeld-virksomhed/#h-der-er-mange-fede-fordele-ved-at-vaere-med" target="_blank">Se fordelene</a>
                <button class="btn btn--green" @click="trigger_contact_modal">Ansøg om fuld adgang</button>
            </div>
        </div>

    </div>
</div>
</template>

<script>


import direktoerAnim from '@/assets/lottie/direktoer.json';
export default {
  
  data() {
    return {
        //animOptions: {animationData: direktoer.default, autoplay: false, loop: true},
        anim: null,
        direktoerAnim
    }
  },
  computed: {
      is_open() {
          let is_open = this.$store.getters.limitedAccess_open

          // Play anim on open only.
          if( this.anim != null ) {
            if( is_open ) {
                this.anim.play()
            } else {
                this.anim.pause()
            }
          }
          return is_open
      }
  },

  methods: {
        close() {
           this.$store.dispatch('limited_access_toggle', 0)
        },

        InitAnim: function(anim) {
            this.anim = anim;
        },

        trigger_contact_modal() {
            this.$store.dispatch('limited_access_toggle', 0)
            this.$store.dispatch('contact_toggle', true)
        }
  },



};
</script>

<style lang="scss">

.limitedAccess {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.3s $easeInOutSine;
    z-index: 9999;

    &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#333, 0.5);

    }

    &__content {
        position: absolute;
        z-index: 2;
        background: white;
        padding: 1rem;
        border-radius: $radius;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -30%);
        max-width: 850px;
        width: 90%;
        transition: 0.3s $easeOutCubic;
        transition-delay: 0.1s;
    }

    &__flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }

    &__text {
        margin-right: 1rem;
        h2 {
            margin-bottom: 1rem;
        }
        p {
            margin-bottom: 1.5rem;
        }
        @include small {
            margin: 2rem 1rem;
        }
    }   

    &__anim {
        flex: 1 0 37%;

        @include small {
            display: none;
        }
    }

    &__close {
        position: absolute;
        width: 35px;
        right: 10px;
        top: 10px;
        padding: 5px;
        border-radius: 50px;
        box-shadow: $shadow2;
        line-height: 0;
        cursor: pointer;
    }

    &.is_open {
        opacity: 1;
        visibility: visible;
        pointer-events: all;

        .limitedAccess__content {
            transform: translate(-50%, -50%);
        }
    }

     @include mobile {
        .btn {
            display: block;
            width: 100%;
            margin-top: 0.8rem;
        }
    }
}
</style>
