<template>

    <div class="loading" v-if="$store.getters.spinner" @click="clickToForceClose">
        <div id="brick_loading">
        <Vue3Lottie :animationData="spinner" :autoPlay="true" :loop="true"/>
        </div>
    </div>

</template>

<script>

import spinner from '@/assets/lottie/brick_loading.json';
export default {
    name: 'loading',
    

    data() {
      return {
        spinner,
        anim: null,
        clicked: 0,
      }
    },

    methods: {
      handleAnimation: function (anim) {
        this.anim = anim;
        this.anim.setSpeed(1.5)  
      },

      clickToForceClose() {

        if( this.clicked > 2) {
          this.$store.dispatch('spinner_toggle', 0)
          this.clicked = 0;
        } else {
          this.clicked++
        }
      }

    },


}
</script>
<style scoped lang="scss">

.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: rgba(white, 0.2);
  
    span {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }

    #brick_loading {
      width: 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
   
  }
  
</style>
