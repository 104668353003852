<template>
    <div class="layout_blank">

        <div class="call">

            <span class="call__prefix">I tvivl om noget?</span>
            <a class="call__number" href="tel:+4577334018">77 33 40 18</a>
            <span class="call__suffix">Kl 9-16 i dag</span>
            <Svgicon icon="phone_desktop" class="phone_desktop"/>

            <a class="phone_mobile" href="tel:+4577334018"><Svgicon icon="phone_mobile"/></a>

          </div>

        <slot></slot>
    </div>
</template>

<style scoped lang="scss">
.layout_blank {
  width: 100%;
  min-height: 100vh;


  .call {
    width: 220px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    right: 0;
    top: 20px;

    &__prefix {
      font-size: 0.75rem;
    }
    &__number {
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.2;
      padding-top: 2px;
    }
    &__suffix {
      font-size: 0.75rem;
    }

    .phone_desktop {
      pointer-events: none;
      position: absolute;
      width: 300px;
      right: -90px;
      bottom: -15px;
    }

    .phone_desktop {
      display: block;
    }
    .phone_mobile {
      display: none;
    }

    @include medium {

      width: auto;
      &__prefix,
      &__number,
      &__suffix  {
        display: none;
      }

      .phone_desktop {
        display: none;
      }
      .phone_mobile {
        display: block;
        width: 30px;
        margin-right: $mobile_gutter;
      }

    }

  }

}
</style>
