<template>
<div class="trialexpired" v-if="is_expired">
    <div class="trialexpired__backdrop" @click="close"></div>
    <div class="trialexpired__content">
        <div class="trialexpired__lottie">
            <Vue3Lottie :animationData="lockAnimation" :autoPlay="true" :loop="true"/>
        </div>
        <div class="trialexpired__text">
         
            
                <h1>Din adgang er udløbet.</h1>
                <p>Skal vi kontakte dig for et uforpligtende snak?</p>
                
            
                <div class="trialexpired__buttons">
                    <a class="btn btn--border btn--large" href="https://www.3byggetilbud.dk/tilmeld-virksomhed/" target="_blank">Se fordelene</a>
                    <button class="btn btn--green btn--large" @click="trigger_contact_modal">Ja, kontakt mig</button>
                </div>
         
        </div>
        

    </div>
</div>
</template>

<script>
import lockAnimation from '@/assets/lottie/lock_lottie_green.json';
export default {

  data() {
    return {
        anim: null,
        lockAnimation,
    }
  },
  computed: {
 
    is_expired() {
        return this.$store.getters.is_expired
    },

        //   days_left() {
        //       return this.$store.getters.is_expired
        //   }
    
  },

  methods: {
        
    trigger_contact_modal() {
            this.$store.dispatch('contact_toggle', true)
    }
  },



};
</script>

<style lang="scss">

.trialexpired {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s $easeInOutSine;
    z-index: 999;

    &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#333, 0.9);

    }

    &__content {
        position: absolute;
        z-index: 2;
        background: white;
        padding: 3rem;
        border-radius: $radius;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 550px;
        width: 90%;
        transition: 0.3s $easeOutCubic;
        transition-delay: 0.1s;
        @include small {
            padding: 2rem 2rem;
        }
        @include mobile {
            padding: 2rem 1rem;
        }
    }

    &__lottie {
        width: 130px;
        margin: 0 auto;
        padding: 20px;
        background: #EAF5EC;
        border-radius: 300px;
        margin-bottom: 3rem;
    }

    
    &__text {
        
        h1 {
            margin-bottom: 1rem;
            text-align: center;
        }
        p {
            margin-bottom: 1.5rem;
            text-align: center;
        }
        
    }   

    &__buttons {
        
        display: flex;
        justify-content: center;
        gap: 1rem;
        
    }

   

    

     @include mobile {

        &__buttons {
            gap: 0rem;
            flex-direction: column;
        }

        .btn {
            display: block;
            width: 100%;
            margin-top: 0.8rem;
        }
    }
}

</style>
