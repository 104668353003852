<template>
  <i class="svgicon" v-html="icon_path" aria-hidden="true"></i>
</template>

<script>
export default {
  props: ['icon'],
  name: 'SvgIcon',

  data () {
    return {
     icon_path : require(`!html-loader!@/assets/${this.icon}.svg`)
    }
  },
}
</script>

<style >

.svgicon {
    text-align: center;
    line-height: 0;
}
.svgicon svg {
  width: 100%;
  height: auto;
}
</style>
