<template>
    <nav class="nav">
    <ul>
        <li v-for="item in menuItems" :key="item.title" :class="{'disabled' : item.disabled}">

            <Svgicon :icon="item.icon" />

            <router-link  v-if="item.to !== undefined" :to="item.to" class="nav__item" @click="routeChanged">{{item.title}}</router-link>

            <a href="#" @click.prevent="trigger_modal" v-else class="nav__item">{{item.title}}</a>

        </li>
    </ul>
    </nav>
</template>

<script>
export default {
    emits: ['click'],
    data() {
        return {
            
        }
    },

    computed: {

          menuItems: function() {
              return [
                { title: 'Oversigt',            icon: 'icons/house',            to: { name: 'Dashboard', hash: this.appendHash } },
                { title: 'Opgaver',             icon: 'icons/doc',              to: { name: 'Opgaver', hash: this.appendHash  } },
                { title: 'Produkter',           icon: 'icons/products',         disabled: true },
                { title: 'Fordelsprogram',      icon: 'icons/tag',              disabled: true },
                { title: 'Guides',              icon: 'icons/guides',           disabled: true },
                { title: 'Min profil',          icon: 'icons/person_circle',    disabled: true },
                { title: 'Underleverandør',     icon: 'icons/people',           disabled: true },
            ]
        },

        appendHash: function() {

            if( this.$store.getters.hash ) {
            return '#' + this.$store.getters.hash
            } else {
                return '';
            }
        },
        trigger_modal() {
            this.$store.dispatch('limited_access_toggle', true)
        },
    },
    methods: {
        routeChanged() {
            this.$emit('routeChanged')
        }
    }

}
</script>


<style scoped lang="scss">

.nav {

    li {
        position: relative;

        &.disabled {
            opacity: 0.5;
            a {
                cursor: not-allowed;
            }
        }
    }

    &__item {
        color: white;
        display: block;
        padding: 19px 15px 15px 15px;
        padding-left: 50px;
        border-radius: 5px;
        margin: 10px 10px;
        line-height: 1;

        &.router-link-active {
            background: $color2;
        }

        &:hover {
            background: $color2;
        }
    }

    .svgicon {
        width: 27px;
        display: block;
        color: white;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
    }

}

</style>