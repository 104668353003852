import { createRouter, createWebHistory } from 'vue-router'
import mixins from '../mixins'
import store from "../store"

const routes = [

  {
    path: '/opretbruger', name: 'Nybruger',
    component: () => import('../views/NewUser.vue'),
    meta: { layout: 'Blank', seotitle: 'Opret bruger' },
  },
  {
    path: '/opretpartner', name: 'Opretpartner',
    component: () => import('../views/NewPartner.vue'),
    meta: { layout: 'Blank', seotitle: 'Opret partner' },
  },
  {
    path: '/', name: 'home',
    component: () => import('../views/Dashboard.vue'),
    meta: { layout: 'Default' },
  },
  {
    path: '/dashboard', name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: { layout: 'Default', seotitle: 'Oversigt' },
  },
  {
    path: '/opgaver', name: 'Opgaver',
    component: () => import('../views/Jobs.vue'),
    meta: { layout: 'Default', seotitle: 'Opgaver' },
  },
  {
    path: '/opgave/:ref', name: 'Opgave',
    component: () => import('../views/Job.vue'),
    meta: { layout: 'Default', seotitle: 'Opgave' },
  },
  {
    path: '/:pathMatch(.*)*', name: 'PageNotExist',
    component: () => import('../views/NewUser.vue'),
    meta: { layout: 'Blank', seotitle: '404' },
  },

 
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})


router.afterEach( (to, from) => {

  mixins.methods.GTM_push_pageview_layer()
  
})

router.beforeEach(async (to, from) => {

  // Save UTM codes in localstorage for later retrieval
  var utm = {};
  if( to.query.utm_source !== undefined ) utm.source = to.query.utm_source;
  if( to.query.utm_campaign !== undefined ) utm.campaign = to.query.utm_campaign;
  if( to.query.utm_medium !== undefined ) utm.medium = to.query.utm_medium;
  
  if( Object.keys(utm).length > 0 ) {
    localStorage.setItem('utm', JSON.stringify(utm));
  }

  // If login page and no hash => login
  if( to.hash == "" && to.name == 'Nybruger') {
    return true;
  }

  // If login page and no hash => login
  if( to.hash == "" && to.name == 'Opretpartner') {
    return true;
  }

  // If url hash is not set
  if( to.hash == "") {

    // Check if hash isset in store.
    let hash = store.getters.hash
    if( !hash ) { // if not in store => login
      return '/opretbruger'
    } 

    return true;

  }

  
  // If hash isset in url and store user emtpy => fetch user.
  // If URL hash isset and doesnt match with store hash => fetch new user from url hash.
  if( !store.getters.current_user || store.getters.hash !== to.hash ) {
    var user = await store.dispatch('fetch_user_from_hash', to.hash)    
  }

  
  return true;
  
})


export default router
