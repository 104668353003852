<template>
<div>
    <leadscoring />
    <component :is="layout">  
      <seo />
      <!-- <transition name="fade">
        <router-view/>
      </transition> -->
      <router-view v-slot="{ Component }">
        <transition>
          <component :is="Component" />
        </transition>
      </router-view>
      
    </component>
    
</div>  

</template>

<script>
import seo from "@/components/seo";
import leadscoring from "@/components/LeadScoring";
const default_layout = 'blank'

export default {
  components: { seo, leadscoring },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '';
    },
  },
  mounted() {

    // Google tag manager
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://gtm.3byggetilbud.dk/ljzicnib.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','GTM-NDSF7F6');
		

  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>