import { createApp } from 'vue'
import App from './App.vue'


import './assets/scss/global.scss'

import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import Default from "./layouts/Default"
import Blank from "./layouts/Blank"

import Svgicon from "./components/Svgicon"
import VueGoogleMaps from 'vue-google-maps-community-fork'

import mixins from './mixins'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Vue3Lottie from 'vue3-lottie'

var app = createApp(App)
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLEMAPS_KEY,
    },
    autobindAllEvents: false,
})

app.use(Vue3Lottie, {name: 'Vue3Lottie' })

app.mixin(mixins)

app.component('Default', Default)
app.component('Blank', Blank)
app.component('Svgicon', Svgicon)

// AXIOS
app.axios.defaults.headers.common['Content-Type'] = 'application/json';
app.axios.defaults.withCredentials = false;
delete app.axios.defaults.headers.common["Authorization"];
store.axios = app.axios;

// TOAST
const options = {
    position: "top-right",
    timeout: 4000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
};
app.use(Toast, options);



app.mount('#app')
