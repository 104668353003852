<template>
    <teleport to="head">
      <title>{{seo}}</title>
    </teleport>
</template>

<script>

export default {

  computed: {
    seo() {

     const suffix = ' | Demo konto';
     var title = 'Demo konto';

      if( this.$route?.meta?.seotitle !== undefined ) {
          var title = this.$route.meta.seotitle + suffix
      }

      return title
    },
  }

}
</script>